import { SearchableGroup } from '@accredible-frontend-v2/models';
import { RecipientApiService } from '@accredible-frontend-v2/recipient-api';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';
import { DirectoryStoreActions } from './directory.actions';
import { DirectoryApiService } from './directory.service';

type LoadDirectorySuccessOrFailure = TypedAction<string> &
  ({ directory: SpotlightDirectory } | { error });

@Injectable()
export class DirectoryEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _directoryApi = inject(DirectoryApiService);
  private readonly _recipientApi = inject(RecipientApiService);

  loadDirectory$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DirectoryStoreActions.loadDirectory),
      exhaustMap((action) => {
        return this._directoryApi.loadDirectory(action.domain, action.propagate404).pipe(
          exhaustMap((directory) =>
            this._loadDirectorySkillsAndAvailableGroups(directory, action.organizationIds),
          ),
          catchError((error) => of(DirectoryStoreActions.loadDirectoryFailure({ error }))),
        );
      }),
    );
  });

  loadDirectoryEligibleGroups$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DirectoryStoreActions.loadEligibleGroups),
      switchMap((action) =>
        this._directoryApi.loadDirectoryGroups(action.directoryId, action.organizationIds).pipe(
          map((response: SearchableGroup[]) => {
            return DirectoryStoreActions.loadEligibleGroupsSuccess({ groups: response });
          }),
          catchError((error) => of(DirectoryStoreActions.loadEligibleGroupsFailure({ error }))),
        ),
      ),
    ),
  );

  loadSkillCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DirectoryStoreActions.loadSkillCategories),
      exhaustMap(() => this._recipientApi.loadSkillCategories()),
      map((categories) => DirectoryStoreActions.loadSkillCategoriesSuccess({ categories })),
      catchError((error) => of(DirectoryStoreActions.loadSkillCategoriesFailure({ error }))),
    ),
  );

  private _loadDirectorySkillsAndAvailableGroups(
    directory: SpotlightDirectory,
    organizationIds: number[],
  ): Observable<LoadDirectorySuccessOrFailure> {
    const skills$ = this._directoryApi.loadDirectorySkills(directory.id);
    const groups$ = this._directoryApi.loadDirectoryGroups(directory.id, organizationIds);
    const observableArray: Observable<SearchableGroup[] | string[]>[] = [skills$];

    // We need to get the eligible groups if all_eligible_groups_searchable is set to true
    if (directory.all_eligible_groups_searchable) {
      observableArray.push(groups$);
    }

    return forkJoin(observableArray).pipe(
      map(
        (
          responseArray,
        ): {
          directory: SpotlightDirectory;
        } & TypedAction<'[Directory] loadDirectorySuccess'> => {
          directory.skills = <string[]>responseArray[0];
          // If we have a groups$ response replace the searchable_groups value with the groups$ response
          if (responseArray[1]) {
            // Filter out any invalid groups
            directory.searchable_groups = (<SearchableGroup[]>responseArray[1]).filter(
              (group) => group.course_name,
            );
          }
          return DirectoryStoreActions.loadDirectorySuccess({ directory });
        },
      ),
      catchError((error) => of(DirectoryStoreActions.loadDirectoryFailure({ error }))),
    );
  }
}
