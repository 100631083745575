import { SearchableGroup, SkillCategory } from '@accredible-frontend-v2/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';

export const DirectoryStoreActions = createActionGroup({
  source: 'Directory',
  events: {
    // Directory
    loadDirectory: props<{ domain: string; propagate404: boolean; organizationIds: number[] }>(),
    loadDirectorySuccess: props<{ directory: SpotlightDirectory }>(),
    loadDirectoryFailure: props<{ error: any }>(),

    // Eligible Groups
    loadEligibleGroups: props<{ directoryId: number; organizationIds: number[] }>(),
    loadEligibleGroupsSuccess: props<{ groups: SearchableGroup[] }>(),
    loadEligibleGroupsFailure: props<{ error: any }>(),

    // Skill Categories
    loadSkillCategories: emptyProps(),
    loadSkillCategoriesSuccess: props<{ categories: SkillCategory[] }>(),
    loadSkillCategoriesFailure: props<{ error: any }>(),
  },
});
