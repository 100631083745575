import { SkillCategory } from '@accredible-frontend-v2/models';
import { AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RecipientApiService extends AccredibleApiService {
  readonly BASE_URL = '/v1/recipient';

  loadSkillCategories(): Observable<SkillCategory[]> {
    return this._get(`${this.BASE_URL}/skill_categories`).pipe(
      map((res) => this._handleResponse(res, 'skill_categories')),
      catchError((res) => this._handleError(res)),
    );
  }
}
